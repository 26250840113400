import { DatePipe, formatDate } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ApiService } from 'app/ts/ApiService';
import { Report } from 'app/ts/models/broker-classes';
import { PreVetRecords, ProcessedRecords, ApprovalRecords, columnHeaders } from 'app/ts/models/broker-interfaces';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-broker-reports',
  templateUrl: './broker-reports.component.html',
  styleUrls: ['./broker-reports.component.scss', '../../forms.shared.scss']
})
export class BrokerReportsComponent implements OnInit {
  
  reports: string[] = [
    'Approval Report',
    //'Letter of Decline',
    'Prevet Report',
    'Process Report'
  ]

  @ViewChild(MatPaginator) paginator: MatPaginator;
  displayedColumns: string[];
  columnsToDisplay: string[];
  dataSource = new MatTableDataSource([]);
  idNumber: string;
  prevets: PreVetRecords[];
  process: ProcessedRecords[];
  approved: ApprovalRecords[];
  reportRequest = new Report();
  staffId: number;
  columns: columnHeaders[];
  reportName: string = '';
  b64Data: string;
  length = 50;
  pageSize = 5;
  pageIndex = 0;
  pageSizeOptions: number[] = [5, 10, 25, 50, 100];
  isAdmin: boolean;
  showResults: boolean;
  filterValue: string;

  pageEvent: PageEvent;

  handlePageEvent(event: PageEvent) {
    this.length = event.length;
    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex;
  }

  setPageSizeOptions(setPageSizeOptionsInput: string) {
    if (setPageSizeOptionsInput) {
      this.pageSizeOptions = setPageSizeOptionsInput.split(',').map(str => +str);
    }
  }
  constructor(private api: ApiService) {
  }

  reportDetails: FormGroup;

  selectChangeHandler(event: { value: string; }): void {
    this.reportName = event.value;
    this.dataSource = new MatTableDataSource([]);
  }

  applyFilter(event: Event) {
    this.filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = this.filterValue?.trim().toLowerCase();
  }

  ngOnInit() {
    this.showResults = true;
  }

  @ViewChild('TABLE', { static: false }) TABLE: ElementRef;
  @ViewChild(MatSort) sort: MatSort;

  title = 'Excel';
  onDownloadClick() {
    var headersArr = [];
    var headers = [];
  
    if (this.dataSource.filteredData.length > 0) {
      // Extract keys from the first object in dataSource.filteredData
      headers = Object.keys(this.dataSource.filteredData[0]);
    }
  
    headersArr.push(headers);
  
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet([]);
    XLSX.utils.sheet_add_aoa(ws, headersArr);
    this.dataSource.filter = this.filterValue?.trim().toLowerCase();
    XLSX.utils.sheet_add_json(ws, this.dataSource.filteredData, { origin: 'A2', skipHeader: true });
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, `${this.reportName}-${formatDate(new Date(), 'yyyy/MM/dd', 'en')}.xlsx`);
  }
  
  getResults(): void {
    this.showResults = false;
  }
  
  getReport(): void {

    this.isAdmin = JSON.parse(sessionStorage.getItem("isSuper"));
    this.staffId = Number(sessionStorage.getItem('staffId'));
    this.reportRequest.staffId = this.staffId;
    this.reportRequest.idNumber = this.idNumber ? this.idNumber : '';
    this.getResults();
    switch (this.reportName) {
      case "Prevet Report":

        this.displayedColumns = [
          'broker_name',
          'channel',
          'date_created',
          'prevetcheck',
          'prevetdate',
          'idNumber',
          'hasDefault',
          'hasJudgment',
          'isDeceased',
          'underDebtReview',
          'hasPPAccounts',
        ];

        this.columns = [
          { DisplayName: 'Broker', DbName: 'broker_name' },
          { DisplayName: 'Channel', DbName: 'channel' },
          { DisplayName: 'Date Created', DbName: 'date_created' },
          { DisplayName: 'Prevet Check', DbName: 'prevetcheck' },
          { DisplayName: 'Prevet Created', DbName: 'prevetdate' },
          { DisplayName: 'Id Number', DbName: 'idNumber' },
          { DisplayName: 'Has Default', DbName: 'hasDefault' },
          { DisplayName: 'Has Judgment ', DbName: 'hasJudgment' },
          { DisplayName: 'Is Deceased', DbName: 'isDeceased' },
          { DisplayName: 'Under Debt Review', DbName: 'underDebtReview' },
          { DisplayName: 'Has PPAdverse', DbName: 'hasPPAccounts' },

        ];

        if (this.isAdmin) {
          this.reportRequest.staffId = 0;
          // this.displayedColumns.splice(this.displayedColumns.length - 1, 0, 'prevetdate');
          // this.columns.splice(this.columns.length - 1, 0, { DisplayName: 'Prevet Date', DbName: 'prevetdate' });
        }

        this.columnsToDisplay = this.displayedColumns.slice();

        this.api.generatePrevetReport(this.reportRequest).subscribe((data: PreVetRecords[]) => {
          this.dataSource.data = data;

        });

        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        break;
      case "Process Report":
        this.reportRequest.idNumber = this.idNumber ? this.idNumber : '';
        this.reportRequest.staffId = this.staffId ? this.staffId : 0;

        this.displayedColumns = [
          'broker_name',
          'channel',
          'client_name',
          'zaid',
          'mobile',
          'note',
          'note_date',
          'submittedDate',
          'switched',
          'updatedBy',
          'updatedDate'
          // 'loan_id',
          // 'loan_status'
        ];

        this.columns = [
          { DisplayName: 'Broker', DbName: 'broker_name' },
          { DisplayName: 'Channel', DbName: 'channel' },
          { DisplayName: 'Client', DbName: 'client_name' },
          { DisplayName: 'Id Number', DbName: 'zaid' },
          { DisplayName: 'Mobile', DbName: 'mobile' },
          { DisplayName: 'Note', DbName: 'note' },
          { DisplayName: 'Note Date', DbName: 'note_date' },
          { DisplayName: 'Submitted On', DbName: 'submittedDate'},
          { DisplayName: 'Switched', DbName: 'switched' },
          { DisplayName: 'Updated By', DbName: 'updatedBy' },
          { DisplayName: 'Updated Date', DbName: 'updatedDate' }
          // { DisplayName: 'Loan Id', DbName: 'loan_id' },
          // { DisplayName: 'Loan Status', DbName: 'loan_status'}
        ]

        this.columnsToDisplay = this.displayedColumns.slice();
        if (this.isAdmin) {
          this.reportRequest.staffId = 0;
        }

        this.api.generateProcessReport(this.reportRequest).subscribe((data: ProcessedRecords[]) => {
          this.dataSource.data = data;

        })

        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        break;

      case "Approval Report":
        this.displayedColumns = [
          'accountNr',
          'approved_principal',
          'productName',
          'loanTerm',
          'approvedDate',
          'brokerName',
          'clientName',
          'clientSurname',
          'external_id',
        ];

        this.columns = [
          { DisplayName: 'Account No', DbName: 'accountNr' },
          { DisplayName: 'Principal', DbName: 'approved_principal' },
          { DisplayName: 'Product', DbName: 'productName' },
          { DisplayName: 'Loan Term', DbName: 'loanTerm' },
          { DisplayName: 'Approved On', DbName: 'approvedDate' },
          { DisplayName: 'Broker', DbName: 'brokerName' },
          { DisplayName: 'Client Name', DbName: 'clientName' },
          { DisplayName: 'Client Surname', DbName: 'clientSurname' },
          { DisplayName: 'Client Id No', DbName: 'external_id' },
        ]

        this.columnsToDisplay = this.displayedColumns.slice();

        if (this.isAdmin) {
          this.reportRequest.staffId = 0;
        }

        this.api.generateApprovalReport(this.reportRequest).subscribe((data: ApprovalRecords[]) => {
          this.dataSource.data = data;
        })

        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;

        break;
      case "Letter of Decline":
        this.reportRequest.idNumber = this.idNumber;
        this.api.getDeclinedLetter(this.reportRequest).subscribe((data: any) => {
          downloadPdf(data, `Decline Letter_client-${this.idNumber}`);
        });
        break;
    }

    function downloadPdf(b64: any, fileName: any) {
      const src = `data:application/pdf;base64, ${b64}`;
      const link = document.createElement('a');
      link.href = src;
      link.download = `${fileName}.pdf`;
      link.click();
    }
  }
  maskRSAID(idNumber: string): string {
    let masked = '';

    if (!this.isAdmin) {
      masked = idNumber.replace(/.(?=.{4})/g, '*');
    }
    else {
      masked = idNumber;
    }

    return masked;
  }
}
